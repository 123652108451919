import { useSessionStore } from '@/stores/session'

const imageBucketUrl = import.meta.env.VITE_S3_BUCKET_URL

export enum BUSINESS_SETTINGS {
  ABBREVIATION = 'business_abbreviation',
  ADDRESS = 'business_address',
  ALLOWED_DOMAINS = 'allowed_domains',
  CUSTOMER_SUPPORT_EMAIL = 'customer_support_email',
  CUSTOMER_SUPPORT_PHONE = 'customer_support_phone',
  CUSTOMER_SUPPORT_URL = 'customer_support_url',
  DOMAIN = 'domain_name',
  FERRY_SCHEDULE_URL = 'ferry_schedule_url',
  LOGO_URL = 'logo_url',
  NAME = 'business_name',
  PRIVACY_POLICY_URL = 'privacy_policy_url',
  TERMS_OF_SERVICE_URL = 'terms_of_service_url'
}

export enum SYSTEM_SETTINGS {
  ALLOWED_DOMAINS = 'allowed_domains'
}

export enum UserRole {
  SYSTEM = 'system',
  ADMIN = 'admin',
  ANALYST = 'analyst',
  SALES = 'sales',
  SUPERVISOR = 'supervisor',
  ALL = 'all'
}

export enum ORDER_STATUSES {
  PENDING = 'Pending',
  INCOMPLETE = 'Incomplete',
  UNCAPTURED = 'Uncaptured',
  CAPTURED = 'Captured',
  COMPLETED = 'Completed',
  REFUNDED = 'Refunded',
  CANCELLED = 'Cancelled',
  PARTIALLY_REFUNDED = 'Partially Refunded'
}

export const ACTION_ROLES = {
  CARD_PASS_CREATE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  CARD_PASS_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  CARD_PASSES_IMPORT: [UserRole.SYSTEM, UserRole.ADMIN],
  CARD_PASSES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  CUSTOMER_DETAILS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  CUSTOMER_PASSWORD_RESET: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  CUSTOMER_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  CUSTOMERS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  CUSTOMERS_EXPORT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  DECRYPT_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  MOBILE_PASS_DETAILS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  MOBILE_PASS_REGISTRATIONS_VIEW: [
    UserRole.SYSTEM,
    UserRole.ADMIN,
    UserRole.SUPERVISOR,
    UserRole.SALES,
    UserRole.ANALYST
  ],
  MOBILE_PASS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  MOBILE_PASS_EXPORT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  MOBILE_PASS_VOID: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  MOBILE_PASS_RESEND: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  MOBILE_PASS_ADD_SCAN: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  NOTE_CREATE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  NOTE_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN],
  NOTES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  ORDER_DETAILS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  ORDER_EMAIL_HISTORY: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  ORDERS_EXPORT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  ORDER_PASSES_PRINT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  ORDER_REFUND: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  ORDER_REFUND_RESEND: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  ORDER_RESEND: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES],
  ORDERS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  PAYLOADS_DECRYPT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  PRODUCT_PUBLISH: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  PRODUCT_DELETE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  PRODUCTS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  QR_CODES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  QR_CODE_EDIT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  QR_CODE_ENABLE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  QR_CODE_DISABLE: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  QR_CODES_EXPORT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  REPORTS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  SCANS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  SETTINGS_CREATE: [UserRole.SYSTEM, UserRole.ADMIN],
  SETTINGS_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN],
  SETTINGS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN],
  USER_CREATE: [UserRole.SYSTEM, UserRole.ADMIN],
  USER_PASSWORD_RESET: [UserRole.SYSTEM, UserRole.ADMIN],
  USER_ROLE_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN],
  USER_UPDATE: [UserRole.SYSTEM, UserRole.ADMIN],
  USERS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  USER_DETAILS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  USER_SECURITY_VIEW: [UserRole.SYSTEM],
  USER_EXPORT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SECURITY_VIEW: [
    UserRole.SYSTEM,
    UserRole.ANALYST,
    UserRole.SALES,
    UserRole.SUPERVISOR,
    UserRole.ADMIN,
    UserRole.SUPERVISOR
  ],
  PAYMENT_TERMINALS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  VESSELS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SIGN_OUT_SESSION: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SIGN_OUT_ALL_SESSIONS: [UserRole.SYSTEM, UserRole.ADMIN],
  SYSTEM_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_BUSINESS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_LOCATIONS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_SALESPOINTS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_PRINTERS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_PRINTER_TYPES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_FARES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_ROUTES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_ZONES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_ZONE_TYPES_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_PAYMENT_TERMINALS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_VESSELS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SETTINGS_SYSTEM_VIEW: [UserRole.SYSTEM],
  VALIDATORS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  VALIDATORS_EXPORT: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  VALIDATOR_DETAILS_VIEW: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  POS_NEW_ORDER_VIEW: [UserRole.ALL],
  SIGN_OUT: [UserRole.ALL],
  AUTO_SIGN_IN: [UserRole.SYSTEM],
  INVITE_ADD_AUTHENTICATOR: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  INVITE_ADD_PASSKEY: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.SALES, UserRole.ANALYST],
  SIDEBAR_PRONTO: [UserRole.SYSTEM, UserRole.ADMIN, UserRole.SUPERVISOR],
  SIDEBAR_STRIPE: [UserRole.SYSTEM]
}

export const MENU_ITEMS = [
  {
    title: 'Orders',
    to: 'orders',
    icon: 'cart-shopping',
    roles: ACTION_ROLES.ORDERS_VIEW
  },
  {
    title: 'Customers',
    to: 'customers',
    icon: 'users',
    roles: ACTION_ROLES.CUSTOMERS_VIEW
  },
  {
    title: 'Passes',
    to: 'passes',
    icon: 'mobile-notch',
    roles: ACTION_ROLES.MOBILE_PASS_VIEW
  },
  {
    title: 'Validators',
    to: 'validators',
    icon: 'box-check',
    roles: ACTION_ROLES.VALIDATORS_VIEW
  },
  {
    title: 'Scans',
    to: 'scans',
    icon: 'barcode-read',
    roles: ACTION_ROLES.SCANS_VIEW
  },
  // {
  //   title: 'Products',
  //   to: 'products'
  // },
  // {
  //   title: 'Cards',
  //   to: 'cards',
  //   icon: 'cards-blank'
  // },
  {
    title: 'QR Codes',
    to: 'qr_codes',
    icon: 'qrcode',
    roles: ACTION_ROLES.QR_CODES_VIEW
  },
  {
    title: 'Reports',
    to: 'reports',
    icon: 'chart-line',
    roles: ACTION_ROLES.REPORTS_VIEW
  },
  {
    title: 'Users',
    to: 'users',
    icon: 'id-card',
    roles: ACTION_ROLES.USERS_VIEW
  },
  {
    title: 'Settings',
    to: 'settings',
    icon: 'gear',
    roles: ACTION_ROLES.SETTINGS_VIEW
  }
]

export const SECONDARY_MENU_ITEMS = [
  {
    title: 'Stripe',
    to: import.meta.env.VITE_STRIPE_DASHBOARD_URL,
    icon: 'stripe-s',
    external: true,
    iconType: 'brands',
    roles: ACTION_ROLES.SIDEBAR_STRIPE,
    onClick: async (event: Event) => {
      const useLoginLink = import.meta.env.VITE_STRIPE_USE_LOGIN_LINK === 'true'

      if (useLoginLink) {
        event.preventDefault()
        const sessionStore = useSessionStore()
        const result = await sessionStore.generateStripeLoginLink()

        if (!result.error) {
          window.open(result.login_link, '_blank')
        }
      }
    }
  },
  {
    title: 'Pronto',
    to: 'https://app.prontocx.com/',
    external: true,
    img: `${imageBucketUrl}/images/icons/pronto-icon.png`,
    roles: ACTION_ROLES.SIDEBAR_PRONTO
  }
]

export const AVAILABLE_CC_ICONS = ['visa', 'mastercard', 'amex', 'discover', 'diners', 'unionpay', 'jcb']

export const MAPPED_CC_NETWORKS: { [network: string]: string } = {
  amex: 'American Express',
  diners: 'Diners Club',
  unionpay: 'UnionPay',
  jcb: 'JCB'
}

const routeOptions = () => {
  const routeFilters = import.meta.env.VITE_ROUTE_FILTER_OPTIONS
  return (routeFilters && routeFilters.split(',')) || []
}

const fareOptions = () => {
  const fareFilters = import.meta.env.VITE_FARE_FILTER_OPTIONS
  return (fareFilters && fareFilters.split(',')) || []
}

export const ORDERS_LIST_FILTERS = [
  {
    name: 'date',
    label: 'Date',
    type: 'dateSelector',
    field: 'completed_at',
    fieldOptions: [
      { label: 'Completed', value: 'completed_at' },
      { label: 'Cancelled', value: 'cancelled_at' },
      { label: 'Created', value: 'inserted_at' },
      { label: 'Refunded', value: 'refunded_at' }
    ]
  },
  {
    name: 'status',
    label: 'Status',
    type: 'checkboxList',
    field: 'status',
    options: [
      { label: 'Incomplete', value: 'Incomplete' },
      { label: 'Uncaptured', value: 'Uncaptured' },
      { label: 'Completed', value: 'Completed' },
      { label: 'Refunded', value: 'Refunded' },
      { label: 'Partially Refunded', value: 'Partially Refunded' },
      { label: 'Pending', value: 'Pending' },
      { label: 'Cancelled', value: 'Cancelled' }
    ]
  },
  {
    name: 'mediaType',
    label: 'Media type',
    type: 'checkboxList',
    field: 'media_type',
    options: [
      { label: 'Card', value: 'card' },
      { label: 'Mobile Pass', value: 'mobile_pass' },
      { label: 'Print', value: 'print' }
    ]
  },
  {
    name: 'route',
    label: 'Route',
    type: 'checkboxList',
    field: 'route',
    options: routeOptions().map((option: string) => ({ label: option, value: option }))
  },
  {
    name: 'fare',
    label: 'Fare',
    type: 'checkboxList',
    field: 'fare',
    options: fareOptions().map((option: string) => ({ label: option, value: option }))
  },
  { name: 'chargedAmount', label: 'Charged amount', type: 'numberRange', field: 'amount_charged' },
  { name: 'discountAmount', label: 'Discount amount', type: 'numberRange', field: 'amount_discount' },
  { name: 'creditAmount', label: 'Credit amount', type: 'numberRange', field: 'amount_credit' },
  {
    name: 'creditTenderType',
    label: 'Credit tender type',
    type: 'checkboxList',
    field: 'credit_tender_type',
    options: [
      { label: 'Cash', value: 'cash' },
      { label: 'Check', value: 'check' },
      { label: 'Gift Certificate', value: 'gift_certificate' }
    ],
    allowOtherOption: true
  },
  { name: 'refundAmount', label: 'Refund amount', type: 'numberRange', field: 'amount_refund' },
  {
    name: 'refunds',
    label: 'Refunds',
    type: 'numberRange',
    field: 'refund_count',
    defaultOptions: { option: 'greater_than', first: 1 }
  },
  {
    name: 'transactionType',
    label: 'Transaction type',
    type: 'checkboxList',
    field: 'transaction_type',
    options: [
      { label: 'Web', value: 'web' },
      { label: 'Terminal', value: 'terminal' }
    ]
  },
  {
    name: 'tenderType',
    label: 'Tender type',
    type: 'checkboxList',
    field: 'pan_funding',
    options: [
      { label: 'Credit Card', value: 'credit' },
      { label: 'Debit Card', value: 'debit' },
      { label: 'Cash', value: 'cash' }
    ]
  },
  {
    name: 'wallet',
    label: 'Wallet',
    type: 'checkboxList',
    field: 'wallet',
    options: [
      { label: 'Apple Wallet', value: 'Apple Wallet' },
      { label: 'Google Wallet', value: 'Google Wallet' },
      { label: 'Other Payment', value: 'Other Payment' }
    ]
  },
  {
    name: 'cardOnFile',
    label: 'Card on file used',
    type: 'dropdown',
    field: 'cof_used',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' }
    ]
  },
  {
    name: 'individualTickets',
    label: 'Individual tickets',
    type: 'dropdown',
    field: 'create_individual_passes',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' }
    ]
  },
  {
    name: 'newCustomer',
    label: 'New customer',
    type: 'dropdown',
    field: 'new_customer',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' }
    ]
  },
  {
    name: 'guestCustomer',
    label: 'Guest customer',
    type: 'dropdown',
    field: 'guest',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' }
    ]
  }
]

export const CUSTOMERS_LIST_FILTERS = [
  {
    name: 'status',
    label: 'Status',
    type: 'dropdown',
    field: 'active',
    options: [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '0' }
    ]
  },
  {
    name: 'guest',
    label: 'Guest',
    type: 'dropdown',
    field: 'guest',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' }
    ]
  },
  { name: 'lastOrder', label: 'Last order', type: 'dateSelector', field: 'last_order_at', period: '' },
  { name: 'ordersCount', label: 'Order', type: 'numberRange', field: 'orders_count' },
  { name: 'lifetimeValue', label: 'Lifetime value', type: 'numberRange', field: 'lifetime_value' },
  { name: 'signInCount', label: 'Sign Ins', type: 'numberRange', field: 'sign_in_count' },
  { name: 'lastSignIn', label: 'Last Sign In', type: 'dateSelector', field: 'last_sign_in_at', period: '' },
  {
    name: 'hasNotes',
    label: 'Has notes',
    type: 'dropdown',
    field: 'has_notes',
    options: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' }
    ]
  },
  { name: 'createdAt', label: 'Created date', type: 'dateSelector', field: 'inserted_at' },
  { name: 'updatedAt', label: 'Updated date', type: 'dateSelector', field: 'updated_at' }
]

export const PASSES_LIST_FILTERS = [
  { name: 'scansCount', label: 'Scans', type: 'numberRange', field: 'scans_count' },
  { name: 'lastScannedAt', label: 'Last scanned', type: 'dateSelector', field: 'last_scanned_at', period: '' },
  { name: 'usesRemaining', label: 'Uses remaining', type: 'numberRange', field: 'use_balance_current' },
  { name: 'expiresAt', label: 'Expires', type: 'dateSelector', field: 'expires_at', period: '' },
  { name: 'voidedAt', label: 'Voided', type: 'dateSelector', field: 'voided_at', period: '' },
  { name: 'createdAt', label: 'Created date', type: 'dateSelector', field: 'inserted_at', period: '' },
  { name: 'updatedAt', label: 'Updated date', type: 'dateSelector', field: 'updated_at', period: '' }
]

export const VALIDATORS_LIST_FILTERS = [
  {
    name: 'status',
    label: 'Status',
    type: 'dropdown',
    field: 'active',
    options: [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '0' }
    ]
  },
  {
    name: 'allowListSynced',
    label: 'Allow List Synced',
    type: 'dateSelector',
    field: 'allow_list_synced_at',
    period: ''
  },
  { name: 'scansSynced', label: 'Scans Synced', type: 'dateSelector', field: 'scans_synced_at', period: '' },
  {
    name: 'lastHeartbeat',
    label: 'Last heartbeat',
    type: 'dropdown',
    field: 'last_heartbeat_at',
    options: [
      { label: 'past hour', value: 'past_hour' },
      { label: 'between 1 and 12 hours', value: 'between_1_and_12_hours' },
      { label: 'between 13 and 24 hours', value: 'between_13_and_24_hours' },
      { label: 'more than 24 hours', value: 'more_than_24_hours' },
      { label: 'anytime', value: 'anytime' },
      { label: 'no heartbeats', value: 'no_heartbeats' }
    ]
  },
  { name: 'createdAt', label: 'Created date', type: 'dateSelector', field: 'inserted_at', period: '' },
  { name: 'updatedAt', label: 'Updated date', type: 'dateSelector', field: 'updated_at', period: '' }
]

export const QR_CODES_LIST_FILTERS = [
  {
    name: 'status',
    label: 'Status',
    type: 'dropdown',
    field: 'active',
    options: [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '0' }
    ]
  },
  { name: 'views', label: 'Views', type: 'numberRange', field: 'impressions' },
  { name: 'createdAt', label: 'Created date', type: 'dateSelector', field: 'inserted_at' },
  { name: 'updatedAt', label: 'Updated date', type: 'dateSelector', field: 'updated_at' }
]

export const USERS_LIST_FILTERS = [
  {
    name: 'status',
    label: 'Status',
    type: 'dropdown',
    field: 'active',
    options: [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '0' }
    ]
  },
  {
    name: 'role',
    label: 'Role',
    type: 'checkboxList',
    field: 'role',
    options: [
      { label: 'Analyst', value: 'analyst' },
      { label: 'Sales', value: 'sales' },
      { label: 'Supervisor', value: 'supervisor' },
      { label: 'Admin', value: 'admin' }
    ]
  },
  { name: 'signInCount', label: 'Sign in count', type: 'numberRange', field: 'sign_in_count' },
  { name: 'lastSignIn', label: 'Last sign in', type: 'dateSelector', field: 'current_sign_in_at' },
  { name: 'createdAt', label: 'Created date', type: 'dateSelector', field: 'inserted_at' },
  { name: 'updatedAt', label: 'Updated date', type: 'dateSelector', field: 'updated_at' }
]

export const SIGN_OUT_NOTIFICATION = 'sign_out_notification=true'

export const VOIDED_REASONS = {
  mistake: 'Created by mistake',
  duplicate: 'Duplicate',
  fraud: 'Fraud',
  customer_request: 'Requested by customer'
}

export enum SERVER_ERRORS {
  BROWSER_MISMATCH = 'browser_mismatch',
  EXPIRED_LINK = 'expired_link',
  INACTIVE_USER = 'inactive_user',
  PASSWORD_RESET_REQUIRED = 'password_reset_required'
}

export const NOTE_KINDS = [
  { label: 'General', value: 'general' },
  { label: 'Customer complaint', value: 'customer_complaint' }
]

export const REFUND_TYPES = {
  FULL: 'full',
  PARTIAL: 'partial'
}

export const TAILWIND_BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
}

export const PASSKEY_ERRORS = {
  INVALID_STATE: 'InvalidStateError',
  NOT_ALLOWED: 'NotAllowedError'
}

export const ORDER_ITEM_TYPES = ['Adult', 'Kid', 'Bike']

// List obtained from https://github.com/prontocx/transit-validator-settings-lambda/blob/main/vessels.mjs
// This list should be fetched from an endpoint but for now is hard-coded
export const VESSELS_LIST = [
  {
    name: 'Commodore',
    code: 'COMM'
  },
  {
    name: 'Courageous',
    code: 'SSCG'
  },
  {
    name: "Martha's Vineyard Express",
    code: 'MVEX'
  },
  {
    name: 'Millennium',
    code: 'MILL'
  },
  {
    name: 'Nantucket Express',
    code: 'NEXP'
  },
  {
    name: 'Ocean State',
    code: 'SSOS'
  },
  {
    name: 'Seastreak Highlands',
    code: 'SSHG'
  },
  {
    name: 'Seastreak New Jersey',
    code: 'SSNJ'
  },
  {
    name: 'Seastreak New York',
    code: 'SSNY'
  },
  {
    name: 'Whaling City Express',
    code: 'WCEX'
  }
]

// List obtained from https://github.com/prontocx/transit-validator-settings-lambda/blob/main/locations.mjs
// This list should be fetched from an endpoint but for now is hard-coded
export const FACILITIES_LIST = [
  {
    name: 'Atlantic Highlands',
    code: 'AH'
  },
  {
    name: 'Highlands',
    code: 'HG'
  },
  {
    name: 'Belford',
    code: 'BEL'
  },
  {
    name: 'Pier 11, Wall Street',
    code: 'P11'
  },
  {
    name: 'East 35th Street',
    code: 'E35'
  },
  {
    name: 'West 39th Street',
    code: 'W39'
  },
  {
    name: 'Brookfield Place',
    code: 'BP'
  },
  {
    name: 'Paulus Hook',
    code: 'PH'
  }
]
